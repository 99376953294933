import { useState } from "react";
import Modal from "./Modal";
import abandonedHouse from "./images/gallery/abandoned-house.png"
import binoculars from "./images/gallery/binoculars.jpg"
import cave from "./images/gallery/cave.png"
import christmasTree from "./images/gallery/christmas-tree.jpg"
import guitar from "./images/gallery/guitar.jpg"
import playingCards from "./images/gallery/playing-cards.jpg"
import ramp from "./images/gallery/ramp.jpg"
import table from "./images/gallery/table.jpg"
import treeStump from "./images/gallery/treestump.jpg"

const Gallery = () => {

    const images = [abandonedHouse, binoculars, cave, christmasTree, guitar, playingCards, ramp, table, treeStump]    
    
    const [clickedImg, setClickedImg] = useState(null);
    
    const handleClick = (image) => {
        setClickedImg(image)
    };

    return (
        <div className="box">
            <h2>Gallery</h2>
            <p>
                Here is a selection of works that I created using Blender 3D.
            </p>
            <div className="gallery">
                <div className="gallery-box">
                    {images.map((image) => (
                        <img src={image} alt="3D work created using Blender" className="gallery-image" onClick={() => handleClick(image)}/>
                    ))}
                </div>
            </div>
            {clickedImg && (
                <Modal 
                    clickedImg={clickedImg}
                    setClickedImg={setClickedImg}
                />
            )}
        </div>
    )
}

export default Gallery