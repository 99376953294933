import { useState } from "react";
import { Link } from "react-router-dom"


export default function Navbar() {

    const [isActive, setActive] = useState(false);
    
    const handleClick = () => {
        setActive(!isActive)
    };


    return (
        <nav className="nav">
            <div className="nav-container">
            <div className="title-box">
                <Link to="/" className="site-title">Laurens Southgate</Link>
                <p>Creative Web Developer</p>
            </div>
            <div className={`navbar-toggle ${isActive ? "is-active" : ""}`} id="mobile-menu" onClick={handleClick}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <ul className={`nav-menu ${isActive ? "active" : ""}`}>
                <li className="nav-item">
                    <Link to="/about" className="nav-links" onClick={handleClick}>About</Link>
                </li>
                <li className="nav-item">
                    <Link to="/portfolio" className="nav-links" onClick={handleClick}>Portfolio</Link>
                </li>
                <li className="nav-item">
                    <Link to="/gallery" className="nav-links" onClick={handleClick}>Gallery</Link>
                </li>
                <li className="nav-item">
                    <Link to="/contact" className="nav-links" onClick={handleClick}>Contact</Link>
                </li>
            </ul>
            </div>
        </nav>
    )
}