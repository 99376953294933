const Modal = ({clickedImg, setClickedImg}) => {
    
    const handleClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
            setClickedImg(null);
        }
    }

    return(
        <>
        <div className="overlay dismiss" onClick={handleClick}>
            <img src={clickedImg} alt="3D Artwork Created using Blender" />
            <span className="dismiss" onClick={handleClick}>x</span>
        </div>
        </>
    )
}

export default Modal;