import Video from "./Video";
import Navbar from "./Navbar";
import About from "./About";
import Portfolio from "./Portfolio";
import Gallery from "./Gallery";
import Contact from "./Contact";
import { Route, Routes } from "react-router-dom"

function App() {
  return (
    <>
      <Video />
      <Navbar />
      <div className="main-body">
        <Routes>
          <Route path="/" />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </>
  )
}

export default App