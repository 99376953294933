import image from "./images/laurens-southgate.jpg"
import { Link } from "react-router-dom"

const About = () => {
    return (
        <div className="box">
            <h2>About Me</h2>
            <div className="about-content">
                <img src={image} alt="Laurens Southgate" className="about-image"/>
                <div className="about-text">
                    <p>
                        Hello, my name is Laurens. I live in Norwich UK and I currently work full time, but am looking to begin a career as a Frontend Web Developer.
                        I am familiar with HTML, CSS (including flexbox and grid), JavaScript, React, JSON, and JQuery. I also have experience using Adobe Photoshop, and
                        Blender. For examples of my web development see the <Link to="/portfolio">Portfolio</Link> section of this site. You can also see examples of my 3D artwork in the <Link to="/gallery">Gallery</Link> section.
                    </p>
                    <p>
                        You can find me on <a href="https://www.linkedin.com/in/laurenssouthgate/" target="_blank" rel="noreferrer noopener">Linkdin</a>, <a href="https://github.com/laurenssouthgate" target="_blank" rel="noreferrer noopener">GitHub</a>, and <a href="https://codepen.io/laurenssouthgate" target="_blank" rel="noreferrer noopener">CodePen</a>. If you wish to get in contact, please use the form in the <Link to="/contact">Contact</Link> section of this site.
                    </p>
                    <p>
                        In my spare time I like to make music, play guitar, draw, play computer games, and eat biscuits. Thanks for visiting my website. If you like my work please
                        don't forget to leave a star on my GitHub repositories! Have a great day! 
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About