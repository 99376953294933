import emailjs from "emailjs-com"
import { useRef } from "react";

export default function Contact() {

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_n6ktpba', 'template_ah10nz4', form.current, '1lzVLo_kgOuu1ovK3')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        e.target.reset()
    }

    return (
        <div className="box">
            <h2>Contact</h2>
            <form onSubmit={sendEmail} ref={form} action="submit" className="contact-form">
                <label htmlFor="name">Name:</label>
                    <input type="text" className="name" name="name" />
                <label htmlFor="email">Email address:</label>
                    <input type="email" className="email" name="email"/>
                <label htmlFor="message">Message:</label>
                    <textarea className="message" name="message" id="message" cols="30" rows="10"></textarea>
                <button className="send-btn" type="submit">Send</button>
            </form>
        </div>
    )
}