import loadtoad from "./images/screenshots/load-toad.jpg"
import prayTheHours from "./images/screenshots/pray-the-hours.jpg"
import randomPoem from "./images/screenshots/random-poem.jpg"
import toDoList from "./images/screenshots/todo-list.jpg"
import smartphone from "./images/screenshots/3d-smartphone.jpg"
import drink from "./images/screenshots/3d-drink-ad.png"

const Portfolio = () => {
    return (
        <div className="box">
            <h2>Portfolio</h2>
            <p>
                Below is a selection of my web development projects. 
            </p>
            <div className="portfolio-item">
                <a href="https://3d-drink-ad.netlify.app/" target="_blank" rel="noreferrer noopener">
                    <img src={drink} alt="3D Drinks Add" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>3D Drink Ad</h3>
                    <p>
                        This was created using React js, with React Three Fiber. Expanding upon my previous project to create a mock drinks advertisement, adding text to the page,
                        as well as experimenting with different parameters within React Three Fiber.
                    </p>
                    <p>
                        Created using React, React Three Fiber, CSS, and Blender. <a href="https://3d-drink-ad.netlify.app/" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/3d-drink-ad" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
            <div className="portfolio-item">
                <a href="https://3d-smartphone.netlify.app/" target="_blank" rel="noreferrer noopener">
                    <img src={smartphone} alt="3D Smartphone" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>3D Smartphone</h3>
                    <p>
                        This was created using React js, with React Three Fiber to import a 3D model that I created using Blender. I wanted to make something that combined my 3D Artwork 
                        with my web development, expanding upon my knowledge of React.
                    </p>
                    <p>
                        Created using React, React Three Fiber, and Blender. <a href="https://3d-smartphone.netlify.app/" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/smartphone" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
            <div className="portfolio-item">
                <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Load-Toad/blob/main/index.html" target="_blank" rel="noreferrer noopener">
                    <img src={loadtoad} alt="Load Toad" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>Load Toad</h3>
                    <p>
                        Load Toad is an app designed to help delivery drivers calculate their vehicle weight easily, and 
                        avoid making their vehicle overweight. The user enters the unloaded vehicle weight, and it's maximum
                        capacity, and is then able to add and remove loads, which represent items placed in the vehicle for 
                        pickup and delivery. The app was created as an exercise in handling user input and making calculations 
                        based off it.
                    </p>
                    <p>
                        Created using HTML, CSS, and Javascript. <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Load-Toad/blob/main/index.html" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/Load-Toad" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
            <div className="portfolio-item">
                <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Pray-the-Hours/blob/main/index.html" target="_blank" rel="noreferrer noopener">
                    <img src={prayTheHours} alt="Pray the Hours" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>Pray the Hours</h3>
                    <p>
                        An app that constructs prayer services known as "the Hours", these services have aspects that change according to
                        the calendar date, according to the date of Easter, and also parts which change according to a cycle that repeats 
                        every 8 weeks. This app was created as an exercise in using the date object effectively, as well as AJAX to access
                        content stored in JSON databases.
                    </p>
                    <p>
                        Created using HTML, CSS, JavaScript, JQuery, and JSON. <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Pray-the-Hours/blob/main/index.html" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/Pray-the-Hours" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
            <div className="portfolio-item">
                <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Random-Poetry-Generator/blob/main/index.html" target="_blank" rel="noreferrer noopener">
                    <img src={randomPoem} alt="Random Poem Generator" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>Random Poem Generator</h3>
                    <p>
                        This app generates a random poem, to the users desired length using randomly picked nouns, adjectives, and verbs from a 
                        database, which are then placed into placeholders in sentences which are also randomly chosen. Mostly made for my own 
                        amusement
                    </p>
                    <p>
                        Created using HTML, CSS, JavaScript, JQuery, and JSON. <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/Random-Poetry-Generator/blob/main/index.html" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/Random-Poetry-Generator" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
            <div className="portfolio-item">
                <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/todo-list/blob/main/index.html" target="_blank" rel="noreferrer noopener">
                    <img src={toDoList} alt="Todo List" className="portfolio-img" />
                </a>
                <div className="portfolio-text">
                    <h3>Todo List</h3>
                    <p>
                        A basic todo list app where the user can add, delete, and tick off items on their list.
                    </p>
                    <p>
                        Created using HTML, CSS, and JavaScript. <a href="https://htmlpreview.github.io/?https://github.com/laurenssouthgate/todo-list/blob/main/index.html" target="_blank" rel="noreferrer noopener">Click here</a> for a preview, and <a href="https://github.com/laurenssouthgate/todo-list" target="_blank" rel="noreferrer noopener">click here</a> to view the GitHub repository.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Portfolio