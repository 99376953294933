import vid from "./images/background-video.mkv"

const Video = () => {
    return (
            <video className="background-video" autoPlay muted loop>
                <source src={vid} />
            </video>
    )
}

export default Video;